<template>
  <div class="main">
    <img class="bg lt" alt="Background" src="@/assets/qbg-lt.png">
    <img class="bg rb" alt="Background" src="@/assets/qbg-rb.png">
    <img class="bg lb" alt="Background" src="@/assets/qbg-lb.png">
    <div :class="{ questionUp, smoothPadding: questionUp && question.multiplier > 1 }" class="question">
      <div v-if="question.multiplier > 1" class="mulltiplier" :class="{ 'd-none': !questionUp }"> {{
          question.multiplier
      }} pkt.
      </div>
      {{ question.question }}
      <img class="logo" alt="logo" src="@/assets/logo.png">
    </div>
    <div :class="{ answersShow, 'open-container': question.type === 'open' }" class="answers">
      <div v-if="testData !== null && testData !== undefined" class="timer questCounter">
        {{ testData.actual_question + 1 }} / {{ testData.questions.length }}
      </div>
      <div class="timer" :class="{ timesUp: !timesUp }"
        v-if="(testData && ((testData.initial_test && !sent) || !testData.initial_test)) && timesUp !== null">
        {{ timesUp }}
      </div>
      <div :class="getClass()">
        <img class="question-img" v-if="question.photo" alt="Obrazek"
          :src="'https://panel.pubquiz.pl/images/knt/question-photo/' + question.photo">
        <div class="answersTile">
          <div v-if="question.type !== 'open'" id="sortable">
            <div v-for="answer in question.answers" :key="answer.id">
              <input :disabled="sent || !timesUp || getClass() === 'arrange'" v-model="myAnswer.choosedAns"
                name="answer" type="radio" :value="answer.id" :id="answer.id">
              <label :for="answer.id">
                <span class="circleSign"><span></span><span></span><span></span></span>
                {{ answer.answer }}
              </label>
            </div>
          </div>

          <label :class="{ 'd-none': question.type !== 'open' }" class="openAns">
            <p>Miejsce na Twoją odpowiedź:</p>
            <textarea :readonly="sent" rows="2" v-model="myAnswer.openAnswer" placeholder="..."></textarea>
          </label>
        </div>
      </div>
    </div>
    <div class="buttons float-end">
      <button v-if="testData && testData.initial_test && (!timesUp || sent)"
        @click="testData.actual_question++; nextQuestion();">
        {{ nextButton }}
        <span>></span>
      </button>
      <button v-if="!sendingAns && ((testData && !testData.initial_test) || !sent) && timesUp" :class="{ sent }"
        @click="sendAnswer()">{{ buttonMessage }}
        <img v-if="sent" alt="ok" src="@/assets/sent.png">
        <img v-if="!sent" alt="ok" src="@/assets/to_send.png">
      </button>
    </div>
    <ExitComponent />
    <WebSocket :gameID="testData ? testData.gameID : 0" @sync="masterSync" @nextQuestion="nextQuestionAction"
      @showRanking="showRankingAction" />

  </div>
</template>

<script>
/* eslint-disable */
import ExitComponent from "@/components/ExitComponent";
import WebSocket from "../components/WebSocket.vue";

export default {
  name: "Game",
  components: { ExitComponent, WebSocket },
  data() {
    return {
      test: "...",
      testData: null,
      questionUp: true,
      sortableOn: false,
      answersShow: true,
      sendingAns: false,
      nextButton: "Następne pytanie ",
      buttonMessage: "Prześlij odpowiedź!",
      sent: false,
      myAnswer: { choosedAns: null, openAnswer: "", order_answer: [] },
      timesUp: null,
      source: null, //eventsource
      question: {
        id: 0,
        photo: null,
        question: null,
        multiplier: null,
        time: 0,
        type: "",
        answers: []
      }
    }
  },
  created() {
    this.testData = this.$route.params.testData;

    window.countInterval = null;

    if (!this.testData)
      this.$router.push("/");
    else {
      const actual = this.testData.questions[this.testData.actual_question];
      // because if phone wake up this function is called again
      if (actual && this.question && actual.id !== this.question.id)
        this.selectQuestion();

      if (!this.testData.passed_time)
        this.testData.passed_time = 0;

      if (!this.testData.waitAnimation)
        this.testData.waitAnimation = 0;

      //if join during the game
      if (!localStorage.getItem("countdown" + this.testData.gameID + this.testData.actual_question)) {
        this.question.time = parseInt(this.question.time - this.testData.passed_time - this.testData.waitAnimation);
      }

      this.testData.passed_time = 0;
      this.testData.waitAnimation = 0;

      clearInterval(this.countInterval);
      window.countInterval = setInterval(this.countdown, 1000);
      if (this.testData.lastAnswer && this.testData.lastAnswer === this.question.id) {
        this.buttonMessage = "Przesłano! ";
        this.sent = true;
        this.sendingAns = false;
      }

    }

  },
  mounted() {
    this.setUpArrange();
  },
  methods: {
    isSent() {
      return this.sent;
    },
    masterSync(data){
      this.question.time += data.time_left - this.timesUp;
      // start timer if stopped
      if(data.time_left > 0 && !this.timesUp){
          this.sendingAns = false;
          window.countInterval = setInterval(this.countdown, 1000);
      }

      if (data.actual_question != this.testData.actual_question){
          this.testData.actual_question = parseInt(data.actual_question);
          this.nextQuestion();
      }

      if(data.rank == 1){
        this.showRankingAction();
      }
      
    },
    nextQuestionAction(actual_question, showTimeDelay, force = false) {
      if (actual_question == this.testData.actual_question && !force)
        return false;

      const self = this;
      setTimeout(function () {
        self.testData.actual_question = parseInt(actual_question);

        const showTime = String(parseInt((new Date()).getTime() / 1000));
        localStorage.setItem("countdown" + self.testData.gameID + self.testData.actual_question, showTime);
        
        self.nextQuestion();
      }, parseFloat(showTimeDelay) * 1000);
    },
    showRankingAction() {
      this.$router.push({
        name: "Ranking",
        params: { testData: this.testData }
      });
    },
    shuffle(a) {
      for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
      }
      return { ...a };
    },
    setUpArrange(callArrange = true) {
      const self = this;
      $("#sortable").sortable({
        revert: true,
        disabled: true,
        update: function () {
          self.myAnswer.order_answer = []
          let i = 0;
          $("#sortable input").each(function () {
            const id = $(this).attr("id");
            self.myAnswer.order_answer.push({ id: id, order_ans: i++ })
          });
        },
      });
      if (callArrange)
        this.setArrange()
    },
    setArrange() {
      if (!this.question || this.question.type !== "arrange") {
        return false;
      }

      //odpowiedz układana jest w kolejność pytań 0,1,2...
      if (!$('#sortable').hasClass('ui-sortable')) {
        this.setUpArrange(false);
      } else if (this.sortableOn || this.sent) {
        // is on
        return false;
      }

      if (this.question && this.question.type === "arrange") {
        const self = this;
        //set default ans
        let i = 0;
        $("#sortable input").each(function () {
          const id = $(this).attr("id");
          self.myAnswer.order_answer.push({ id: id, order_ans: i++ })
        });

        this.sortableOn = true;
        $("#sortable").sortable('option', 'disabled', false);
      } else if (this.sortableOn) {
        this.sortableOn = false;
        $("#sortable").sortable('option', 'disabled', true);
      }
    },
    nextQuestion() {
      this.answersShow = false;
      this.sendingAns = false;
      const self = this;
      this.myAnswer.choosedAns = null
      this.myAnswer.openAnswer = ""
      this.myAnswer.order_answer = []
      if (!this.sent) {
        this.sendAnswer()
      }
      clearInterval(window.countInterval);

      setTimeout(function () {
        self.sent = false;
        self.selectQuestion();
        window.countInterval = setInterval(self.countdown, 1000);
        self.answersShow = true;
        self.setArrange();
        self.buttonMessage = "Prześlij odpowiedź!";
      }, 500);

    },
    countdown() {
      //save time in situation where mobile phone is shutdown
      if (!localStorage.getItem("countdown" + this.testData.gameID + this.testData.actual_question))
        localStorage.setItem("countdown" + this.testData.gameID + this.testData.actual_question, String(parseInt((new Date()).getTime() / 1000)));

      const countdown = parseInt(localStorage.getItem("countdown" + this.testData.gameID + this.testData.actual_question));
      const now = parseInt((new Date()).getTime() / 1000);
      this.timesUp = this.question.time + countdown - now;
      
      // test
      const t1 = new Date(countdown*1000);
      const t2 = new Date(now*1000);
      this.test = this.question.time + '<br> ' + t1.getHours() +":"+t1.getMinutes() +":"+t1.getSeconds();
      this.test += '<br> ' + t2.getHours() +":"+t2.getMinutes() +":"+t2.getSeconds();
      // end test

      // to be sure that function is fire when could be
      this.setArrange();

      if (this.timesUp <= 0) {
        clearInterval(this.countInterval);
        this.timesUp = 0;
        this.sendingAns = true; //hide button
        if (this.sortableOn) {
          this.sortableOn = false;
          $("#sortable").sortable('option', 'disabled', true);
        }
      }
    },
    getClass() {
      return this.question.type === "arrange" ? "arrange" : (this.question.type === "open" ? "open" : "closed");
    },
    selectQuestion() {
      //const self = this;
      if (this.testData.actual_question === null) {
        this.$router.push({
          name: "WaitForStart",
          params: { testData: this.testData }
        });
      }

      if (this.testData.questions.length <= this.testData.actual_question) {
        localStorage.clear();
        this.$router.push("/?endgame=1");
      } else {
        this.question = { ...this.testData.questions[this.testData.actual_question] };
        this.question.answers = this.shuffle(this.question.answers)
      }
      if (this.testData.actual_question === this.testData.questions.length - 1)
        this.nextButton = "Zakończ test";
    },
    sendAnswer() {
      if (this.sent)
        return false;

      if (this.sortableOn) {
        this.sortableOn = false;
        $("#sortable").sortable('option', 'disabled', true);
      }

      if (!this.timesUp) {
        this.myAnswer.openAnswer = "";
        this.myAnswer.choosedAns = "";
      }

      this.sendingAns = true
      const params = new URLSearchParams();
      params.append("question_id", this.question.id);
      params.append("answer_id", this.myAnswer.choosedAns);
      params.append("answer_open", this.myAnswer.openAnswer);
      params.append("order_ans", JSON.stringify(this.myAnswer.order_answer));
      const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }, };

      this.axios.post("/knt/send-answer", params, config)
        .then(() => {
          this.buttonMessage = "Przesłano! ";
          this.sent = true;
          this.sendingAns = false;
        })
        .catch((error) => {
          this.sendingAns = false;
          console.log(error);
          if (error.response.status === 401) {
            localStorage.clear();
            this.$router.push("/");
          }
        });
    }
  }
}
</script>

<style scoped>
.smoothPadding {
  padding-left: 150px !important;
}

@media (max-width: 670px) and (max-height: 850px) {
  .question-img {
    max-width: 90% !important;
    max-height: 220px;
  }
}

@media (max-width: 800px) {
  .logo {
    bottom: -45px !important;
  }
}

@media (max-width: 640px) {
  .answers {
    top: 30% !important;
    height: 70vh !important;
  }

  .smoothPadding {
    padding-left: 105px !important;
  }

  .questionUp {
    padding-right: 20px !important;
    padding-bottom: 35px !important;
  }

  .question-img {
    margin-top: 90px !important;
    max-width: 80% !important;
  }

  .answersTile div {
    width: 90% !important;
  }

  .openAns p {
    font-size: 1.05em !important;
  }

  .closed,
  .arrange,
  .open {
    padding: 2% 0 !important;
  }
}

@media (min-width: 1000px) {
  .buttons button {
    height: 62px !important;
    font-size: 1.3em !important;
    max-width: 350px !important;
  }

  .buttons button img {
    top: 13px !important;
  }

  .buttons span {
    top: 13px !important;
    line-height: 1.3em !important;
  }

  .question {
    font-size: 1.8em !important;
  }

  .answersTile label {
    padding: 0.5em !important;
    font-size: 1.4em;
  }
}

.mulltiplier {
  background: #003e72;
  display: inline-block;
  height: 80px;
  left: 2%;
  top: -3px;
  margin-bottom: 45px;
  white-space: nowrap;
  font-size: 0.74em !important;
  line-height: 6.5em;
  text-align: center;
  border-radius: 4px;
  color: white;
  position: absolute;
  width: 80px;
}

.mulltiplier:before {
  border-top: 30px solid #003e72;
  border-left: 39px solid transparent;
  border-right: 39px solid transparent;
  content: "";
  height: 0;
  left: 1px;
  position: absolute;
  top: 79px;
  width: 0;
}

.logo {
  position: absolute;
  max-width: 190px;
  min-width: 140px;
  width: 20%;
  right: 0;
  cursor: pointer;
  bottom: -80px;
}

.questCounter {
  left: 0 !important;
  word-spacing: -0.1em;
  white-space: nowrap;
}

.timer {
  position: absolute;
  top: 15px;
  left: 95px;
  color: white;
  height: 80px;
  width: 80px;
  font-weight: 800;
  font-size: 1.25em;
  padding-top: 3px;
  z-index: 1;
  justify-content: center;
  display: flex;
  align-items: center;
  margin: 0 0 1em 1em;
  border-radius: 100%;
  border: 3px solid white;
}

.timesUp {
  color: #dd0000 !important;
  border: 3px solid #dd0000 !important;
}

.openAns {
  background: none !important;
  color: #003e72;
  font-weight: 800;
  margin: 20px 0;
  text-align: center;
}

.openAns p {
  width: 94%;
  padding-left: 3%;
  text-align: left;
  font-size: 1.3em;
  color: white;
  margin-bottom: 7px;
}

.openAns textarea {
  background-color: white;
  border-radius: 50px;
  width: 95%;
  font-weight: 800;
  border: none;
  box-shadow: 4px 7px 3px #003e72;
  padding: 20px 20px 0 20px;
}

.buttons {
  /* 17, 212, 31*/
  position: fixed;
  right: 3%;
  bottom: 0;
  margin-bottom: 30px !important;
  z-index: 2;
  text-align: right;
}

.sent {
  color: rgb(17, 212, 31) !important;
}

.buttons button {
  font-size: 0.85em;
  height: 52px;
  border: none;
  line-height: 1.1em;
  border-radius: 50px;
  background-color: #f6f6f6;
  font-weight: 700;
  max-width: 150px;
  text-align: left;
  box-shadow: 2px 2px 5px #aaa inset, 0 2px 5px #444;
  margin-top: 1.2em;
  padding: 12px 50px 10px 15px;
  position: relative;
}

.buttons span {
  position: absolute;
  border-radius: 100%;
  font-size: 1.8em;
  text-align: center;
  top: 7px;
  right: 8px;
  line-height: 1.6em;
  border: 2px solid black;
  width: 37px;
  height: 37px;
}

.buttons button img {
  width: 37px;
  position: absolute;
  right: 8px;
  top: 8px;
}

.closed,
.arrange,
.open {
  font-weight: 700;
  padding: 2% 4%;
  height: 100%;
  color: black;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}

.question-img {
  max-height: 35vh;
  max-width: 600px;
  margin-top: 30px;
  margin-left: 10px;
  min-width: 220px;
}

.answersTile {
  width: 100%;
}

.answersTile div {
  margin: 0 auto;
}

.closed #sortable {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.arrange #sortable {
  justify-content: space-around;
  width: 80%;
  margin: 0 auto 20px auto;
}

.arrange #sortable div {
  cursor: pointer;
}

.closed #sortable div {
  max-width: 550px;
  width: 48%;
}

.arrange .circleSign {
  position: absolute;
  left: 7px;
  width: 30px;
  top: 0;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.arrange .circleSign span {
  width: 7px;
  height: 7px;
  margin: 1px;
  border-radius: 100%;
  background-color: #003e72;
}

.answersTile #sortable div {
  margin: 1.2em 0;
}

.answersTile label {
  width: 100%;
  position: relative;
  background-color: white;
  border-radius: 30px;
  padding: 7px 20px 7px 20px;
}

.answersTile input:checked+label {
  background-color: #FFDE59;
  color: #003e72;
}

.answersTile input {
  display: none;
}

.main {
  width: 100vw;
  position: relative;
  overflow-x: hidden;
  height: 100vh;
  background-color: #003e71;
}

.question {
  background-color: white;
  font-weight: 900;
  border-radius: 50px;
  transition-duration: 1.5s;
  font-size: 1.45em;
  width: 90vw;
  display: flex;
  align-items: center;
  position: relative;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  top: 50%;
  max-width: 1000px;
  z-index: 1;
  padding: 0.75em;
}

.questionUp {
  top: 0 !important;
  transform: translateX(-50%) translateY(0) !important;
  border-radius: 0;
  text-align: left;
  padding-left: 4%;
  min-height: 75px;
  padding-right: 150px;
  max-width: 100vw;
  width: 100vw;
}

/*
.open-container {
  background-color: rgba(255, 255, 255, 0.5) !important;
}
*/

.answers {
  position: relative;
  background-color: rgba(0, 0, 0, 0.3);
  width: 95vw;
  margin: 40px auto 4vh auto;
  overflow: auto;
  transition-duration: 1s;
  height: 89vh;
  opacity: 0;
  left: 47.5%;
  transform: translateX(-50%) translateY(-50%);
  top: 40%;
}

.answersShow {
  opacity: 1 !important;
}

.bg {
  position: absolute;
}

.lt {
  left: 0;
  top: 0;
  object-fit: cover;
  max-height: 62vh;
  max-width: 44vw;
  min-width: 250px;
}

.rb {
  bottom: 0;
  right: 0;
  max-width: 40vw;
  max-height: 40vh;
  min-width: 240px;
}

.lb {
  bottom: 0;
  left: 0;
  max-width: 17vw;
  min-width: 110px;
  max-height: 20vh;
}

textarea {
  outline: none;
}
</style>