<template>
  <div>

  </div>
</template>

<script>

export default {
  name: "WebSocket",
  props: ['gameID'],
  data() {
    return {
      
    }
  },
  mounted() {
    if(!window.Echo)
      return false;

    window.Echo.leaveChannel('knt-game-id' + this.gameID);
    window.Echo.channel('knt-game-id' + this.gameID)
      .listen('.UpdateGameState', (e) => {
        // console.log(e);
        switch (Object.keys(e)[0]) {
          case 'next_question':
            this.$emit('nextQuestion', e.next_question, e.showTimeDelay);
            break;
          case 'show_ranking':
            this.$emit('showRanking', 1);
            break;
          case 'sync':
            this.$emit('sync', {
              'actual_question': e.a_quest,
              'time_left': e.q_time,
              'rank': e.rank
            });
            break;
        }
      });
  },
  methods: {

  }
}
</script>

<style scoped>
</style>