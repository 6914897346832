import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import router from './router'
import 'bootstrap'
import Echo from 'laravel-echo';
// eslint-disable-next-line
import * as Pusher from 'pusher-js';

Vue.config.productionTip = false
export const bus = new Vue();

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')


//window.pusher = new Pusher(process.env.VUE_PUSHER_APP_KEY);

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.VUE_APP_PUSHER_APP_KEY,
    wsHost: process.env.VUE_APP_WEBSOCKETS_SERVER,
    wsPort: process.env.VUE_APP_WEBSOCKETS_PORT,
    wssPort: process.env.VUE_APP_WEBSOCKETS_PORT,
    forceTLS: process.env.VUE_APP_ENV === 'production', //in production wss
    disableStats: true
});

//global axios & api url
const base = axios.create({
  headers: {
    'Accept': 'application/json',
  },
  baseURL: process.env.VUE_APP_API_URL
});

Vue.prototype.axios = base;