<template>
  <div class="container-custom">
    <div class="title-container">
      Zalogowani gracze:
      <img class="logo" alt="logo" src="@/assets/logo.png">
    </div>
    <div class="count">
      <h4>{{ players.length }}</h4>
    </div>
    <div class="players-container">
      <div class="allPlayers">
        <div class="emptyPlayer" v-if="players.length === 0">
          Brak graczy
        </div>
        <div v-else :key="player" v-for="player in players" :class="{ itsme: player === testData.player_name }">
          {{ player }}
        </div>
      </div>
      <div class="countdown" v-if="countdown > 0">
        {{ countdown }}
      </div>
    </div>
    <ExitComponent />
    <WebSocket :gameID="testData ? testData.gameID : 0" @nextQuestion="startGameAction" />

  </div>
</template>

<script>

import ExitComponent from "@/components/ExitComponent";
import WebSocket from "../components/WebSocket.vue";
export default {
  name: "WaitForStart",
  components: { ExitComponent, WebSocket },
  data() {
    return {
      testData: null,
      countdown: 0,
      runTimer: false,
      players: [],
      eventSource: null,
    }
  },
  created() {
    this.testData = this.$route.params.testData;
    if (!this.testData)
      this.$router.push("/");
    else {
      this.testData.waitAnimation = 0;
      setTimeout(this.syncTime, 500);

      const self = this;
      let lastFired = new Date().getTime();
      setInterval(function () {
        const now = new Date().getTime();
        if (now - lastFired > 3000) {//if it's been more than 3 seconds
          self.syncTime();
        }
        lastFired = now;
      }, 2000);
    }
  },
  watch: {
    countdown() {
      this.eventSource.close();

      if (this.countdown > 0) {
        const self = this;
        this.runTimer = true;
        setTimeout(function () {
          self.countdown = self.countdown - 1;
        }, 1000);

      } else if (this.runTimer) {
        this.runTimer = false;
        this.testData.passed_time = 0;
        this.$router.push({
          name: "Game",
          params: { testData: this.testData }
        });
      }
    }
  },
  methods: {
    startGameAction(actual_question, showTimeDelay) {
      this.testData.actual_question = parseInt(actual_question);
      const showTime =  String(parseInt((new Date()).getTime() / 1000) + parseFloat(showTimeDelay));
      localStorage.setItem("countdown" + this.testData.gameID + this.testData.actual_question, showTime);
      this.countdown = parseFloat(showTimeDelay);
    },
    syncTime() {
      const self = this;
      const params = `token=${localStorage.getItem("token")}`;
      const link = this.axios.defaults.baseURL + "/knt/players?";
      if (this.eventSource)
        this.eventSource.close();

      // TODO: change to send players with websocket
      this.eventSource = new EventSource(link + params);
      this.eventSource.onmessage = function (event) {
        const out = JSON.parse(event.data);
        self.players = out["players"];

        if (out['question'] !== null) {
          self.testData.actual_question = out['question'];
          if (out["show_time"] <= 0) {
            self.countdown = 1;
            self.testData.questions[self.testData.actual_question].time += (parseInt(out["show_time"]) - 2);
          } else {
            self.countdown = parseInt(out["show_time"]);
          }
        }
      };
    }
  }
}
</script>

<style scoped>
@media (max-width: 800px) {
  .logo {
    bottom: -60px !important;
  }

  .title-container {
    font-size: 1.4em !important;
    padding-left: 1em !important;
  }

  .logo {
    right: 0 !important;
    top: 55% !important;
  }
}

* {
  font-weight: 900;
}

.countdown {
  position: absolute;
  right: 10%;
  bottom: 10%;
  font-size: 2.5em;
  color: white;
  border-radius: 100%;
  border: 3px solid white;
  width: 100px;
  transition-duration: .3s;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.itsme {
  border-radius: 50px;
  background-color: whitesmoke;
  color: #074c85 !important;
}

.emptyPlayer {
  padding: 1% 10%;
  font-size: 2.2em !important;
}

.allPlayers {
  margin-top: 1em;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 370px));
}

.allPlayers div:first-child {
  margin-top: 0 !important;
}

.allPlayers div {
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: whitesmoke;
  font-size: 1.5em;
  margin: 0.5em 1em;
}

.logo {
  position: absolute;
  max-width: 260px;
  min-width: 140px;
  width: 30%;
  right: 1em;
  cursor: pointer;
  top: 20%;
}

.title-container {
  background-color: white;
  height: 100px;
  position: relative;
  font-size: 2em;
  text-align: left;
  display: flex;
  align-items: center;
  padding-left: 2em;
  border-bottom: 13px solid #073e6c;
  color: #074c85;
}

.players-container {
  background-color: #074c85;
  height: 70vh;
  overflow: auto;
}

.container-custom {
  width: 100%;
  height: 100vh;
  background-color: #074c85;
}

.count {
  text-align: left;
  padding-left: 2.5em;
  padding-top: 1.5em;
  color: whitesmoke;
}

.count h4 {
  font-size: 3em;
}
</style>