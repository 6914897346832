<template>
  <div ref="container" class="custom-container">
    <img class="bg lt" alt="Background" src="@/assets/rbg-lt.png">
    <img class="bg rt" alt="Background" src="@/assets/rbg-rt.png">
    <img class="bg rb" alt="Background" src="@/assets/rbg-rb.png">
    <img class="bg lb" alt="Background" src="@/assets/rbg-lb.png">

    <table ref="kntTable" class="table table-sm kntTable">
      <thead>
      <tr>
        <td>
          <img ref="logo" class="logo" src="@/assets/logo.png" alt="Logo">
        </td>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td class="topContainer">
          <div v-for="(player, i) in playersTop" ref="top" :key="player.name + i" class="rankTop"
               :class="{gold: i === 0, silver: i === 1, bronze: i === 2}">
            <div>
              <p>{{ player.name }}</p>
              <div class="insideDiv">
                <img alt="rank" :src="getMedal(i)"/>
                {{ player.result }} pkt
              </div>
            </div>
          </div>
        </td>
      </tr>
      </tbody>
    </table>

    <div ref="animation" class="position-relative overflow-auto">
      <table class="table table-sm kntTable">
        <tbody>
        <tr>
          <td></td>
        </tr>
        <tr v-for="(player, i) in playersOthers" :key="player.name + (i + 4)">
          <td :class="{itsme: player.name === testData.player_name}" class="othersRow">{{ i + 4 }}.<span class="ps-4">{{ player.name }}</span> <span
              class="float-end">{{ player.result }} pkt</span></td>
        </tr>
        </tbody>
      </table>
    </div>
    <div v-if="loaderImage" class="custom-loader loadingio-spinner-dual-ball-2pd4am92pg">
      <div class="ldio-4utaa9ogoxl">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="custom-loader" v-if="loaderMessage">{{ loaderMessage }}</div>
    <ExitComponent/>
    <WebSocket :gameID="testData ? testData.gameID : 0" @sync="masterSync" @nextQuestion="nextQuestionAction" />

  </div>
</template>

<script>
import ExitComponent from "@/components/ExitComponent";
import WebSocket from "../components/WebSocket.vue";

export default {
  name: "Ranking",
  components: { ExitComponent, WebSocket },
  created() {
    this.testData = this.$route.params.testData;
    if (!this.testData)
      this.$router.push("/");
    else {
      this.testData.waitAnimation = 0;
      this.getRank();
      this.synchronize();

      window.addEventListener("focus", this.synchronize);

    }
  },
  data() {
    return {
      testData: null,
      playersTop: [],
      playersOthers: [],
      loaderImage: true,
      loaderMessage: "",
      countdown: 0,
      runTimer: false,
      eventSource: null
    }
  },
  methods: {
    nextQuestionAction(actual_question, showTimeDelay) {
      if (actual_question == this.testData.actual_question)
        return false;
      
      console.log(actual_question + " " +showTimeDelay);
      return false;
      // this.testData.passed_time = 0;
      // const showTime =  String(parseInt((new Date()).getTime() / 1000) + parseFloat(showTimeDelay));
      // this.testData.actual_question = parseInt(actual_question);
      // localStorage.setItem("countdown" + this.testData.gameID + this.testData.actual_question, showTime);
      // this.countdown = showTimeDelay;
    },
    masterSync(data){
      if (data.actual_question != this.testData.actual_question){
          this.testData.actual_question = parseInt(data.actual_question);
          this.nextQuestionAction();
      }
    
    },
    synchronize() {
      // const self = this;
      // setTimeout(function () {
      //   const params = `token=${localStorage.getItem("token")}`;
      //   const link = self.axios.defaults.baseURL + "/knt/question-sync?";
      //   if(self.eventSource)
      //     self.eventSource.close();

      //   self.eventSource = new EventSource(link + params);
      //   self.eventSource.onmessage = function (event) {
      //     const out = JSON.parse(event.data);

      //     if(out['rank'] === 0) {
      //       self.testData.actual_question = out['question'];
      //       event.target.close();

      //       if(out["show_time"] <= 0){
      //         self.countdown = 1;
      //         self.testData.questions[self.testData.actual_question].time += (parseInt(out["show_time"])-2);
      //       }else{
      //         self.countdown = parseInt(out["show_time"]);
      //       }

      //     }
      //   };
      // }, 500);

    },
    getMedal(place) {
      const name = place === 0 ? "gold" : (place === 1 ? "silver" : "bronze");
      const images = require.context('../assets/', false, /\.png$/)
      return images('./' + name + ".png")
    },
    getRank() {
      this.loaderImage = true;

      this.axios.get("/knt/rank/" + this.testData.gameID)
          .then((response) => {
            this.loaderImage = false;
            const res = JSON.parse(response.request.response);

            if (res['players'].length === 0) {
              this.loaderMessage = "Brak graczy w grze";
            } else {
              this.playersTop = res['players'].slice(0, 3);
              this.playersOthers = res['players'].slice(3);
              setTimeout(this.runAnimation, 500);
            }
          })
          .catch((error) => {
            this.loaderMessage = "Wystąpił błąd, spróbuj ponownie";
            this.loaderImage = false;
            console.log(error);
          });
    },
    runAnimation() {
      const tops = this.$refs.top;
      if(this.playersTop[0])
        tops[0].style.opacity = 1;

      const self = this;
      if (this.playersTop[1])
        setTimeout(function () {
          tops[1].style.opacity = 1;
        }, 750);

      if (this.playersTop[2])
        setTimeout(function () {
          tops[2].style.opacity = 1;
        }, 1600);

      setTimeout(function () {
        self.$refs.logo.className += " smallLogo";
      }, 2400);

      setTimeout(function () {
        self.$refs.kntTable.className += " topTableAnim";
        self.$refs.animation.className += " secondTable";
      }, 2500);

      setTimeout(function (){
        self.$refs.container.style.overflowY = "auto";
      }, 4000);
    }
  },
  watch: {
    countdown() {
      if (this.countdown > 0) {

        const self = this;
        this.runTimer = true;
        setTimeout(function () {
          self.countdown = self.countdown - 1;
        }, 1000);

      } else if (this.runTimer) {
        this.runTimer = false;
        this.testData.passed_time = 0;
        if (this.testData.questions.length <= this.testData.actual_question) {
          localStorage.clear();
          this.$router.push("/?endgame=1");
        } else {
          this.$router.push({
            name: "Game",
            params: {testData: this.testData}
          });
        }
      }
    }
  }
}
</script>

<style scoped>
.smallLogo {
  width: 25% !important;
  margin-top: 20px !important;
  left: 68.5vw !important;
}

@media (max-width: 700px) {
  .kntTable {
    width: 100% !important;
    padding: 50px 0 !important;
  }

  .rankTop {
    height: 140px !important;
  }

  .rankTop p {
    font-size: 1em !important;
  }

  .rankTop:first-child p {
    max-width: 190px;
  }

  .rankTop img {
    width: 35px !important;
    top: -1px !important;
  }

  .insideDiv {
    font-size: 0.8em;
    max-width: 140px;
  }

  .topContainer {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .topContainer div {
    flex: 1 0 50%;
  }

  .topContainer div:first-child {
    flex: 0 1 100%;
  }

  .logo {
    top: -100px !important;
  }

  .topTableAnim {
    top: 10px !important;
  }
}

@media (min-width: 1400px) {
  .smallLogo {
    right: -10vw !important;
    left: auto !important;
  }
}

@media (max-height: 800px) {
  .secondTable {
    top: 300px !important;
  }
  .topTableAnim {
    top: -40px !important;
  }
  .smallLogo {
    top: 20px !important;
  }

  .overflow-auto {
    height: 70vh !important;
  }
}
@media (min-height: 800px) {
  .smallLogo {
    top: -80px !important;
  }
  .topTableAnim {
    top: 8% !important;
  }
  .secondTable {
    top: 47vh !important;
  }
}

@media (max-width: 550px) {
  .topTableAnim {
    top: -30px !important;
  }

  .secondTable {
    top: 50vh !important;
  }

  .overflow-auto {
    height: 40vh !important;
  }

  .smallLogo {
    top: 20px !important;
  }
}
.overflow-auto {
  transition-duration: 1.5s;
  top: 100vh;
  height: 45vh;
  width: 100vw;
}

.overflow-auto {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}

.overflow-auto::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.overflow-auto .kntTable {
  margin-top: 0 !important;
  padding-top: 0 !important;
  top: 20px;
}

.itsme{
  color: white !important;
  background-color: #003e72 !important;
}

.othersRow {
  text-align: left;
  padding-left: 1em;
  padding-right: 1em;
}

.topContainer {
  display: flex;
  justify-content: space-between;
}

.logo {
  width: 70%;
  position: absolute;
  top: -90px;
  left: 0;
  right: 0;
  margin: 0 auto;
  min-width: 100px;
  transition-duration: 1s;
  max-width: 220px;
}

.gold {
  background-image: url('~@/assets/rank_gold.png');
}

.silver {
  background-image: url('~@/assets/rank_silver.png');
}

.bronze {
  background-image: url('~@/assets/rank_bronze.png');
}

.slowlyShow {
  opacity: 1 !important;
}

.custom-container {
  width: 100vw;
  height: 100vh;
  transition-duration: 1.5s;
  position: relative;
  overflow: hidden;
  background-color: #187fd4;
}

.rankTop p {
  font-size: 1.15em;
  width: 80%;
  margin: 0 auto;
  padding: 10px 10%;
}

.custom-loader {
  position: absolute;
  right: 0;
  left: 0;
  top: 10%;
  bottom: 0;
  margin: auto;
  color: white;
  font-weight: 700;
  font-size: 1.2em;
  height: 100px;
}

.kntTable:not(:first-child) {
  max-width: 1200px !important;
}

.kntTable {
  position: absolute;
  left: 0;
  top: 200px;
  max-width: 950px;
  right: 0;
  transition-duration: 1.5s;
  border-collapse: separate;
  border-spacing: 1em;
  font-family: Spartan, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  font-weight: 800;
  width: 90%;
  margin: auto;
  padding-top: 100px;
  color: #07365d;
}

.kntTable td {
  vertical-align: middle;
  border: none;
}

.rankTop {
  opacity: 0;
  transition-duration: 1.5s;
  color: black;
  width: 33%;
  display: flex;
  height: 250px;
  align-items: center;
  justify-content: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.rankTop > div {
  width: 90%;
}

.insideDiv {
  color: #07365d;
  margin: 0 auto;
  background-color: white;
  border-radius: 50px;
  width: 77%;
  padding: 2px 0;
  position: relative;
}

.rankTop img {
  position: absolute;
  top: -2px;
  left: -8px;
  width: 45px;
}

.kntTable tbody tr:not(:first-child) td {
  background-color: white;
  vertical-align: middle;
  border-radius: 50px;
  margin: 20px 10%;
}

.bg {
  position: absolute;
}

.lt {
  left: 0;
  top: 0;
  object-fit: cover;
  max-height: 62vh;
  max-width: 44vw;
  min-width: 250px;
}

.rt {
  right: 0;
  top: 0;
  max-width: 28vw;
  max-height: 20vh;
  min-width: 100px;
}

.rb {
  bottom: 0;
  right: 0;
  max-width: 40vw;
  max-height: 40vh;
  min-width: 240px;
}

.lb {
  bottom: 0;
  left: 0;
  max-width: 24vw;
  min-width: 110px;
  max-height: 23vh;
}
</style>