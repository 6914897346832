import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "@/views/Home";
import WaitForStart from "@/views/WaitForStart";
import Game from "@/views/Game";
import Ranking from "@/views/Ranking";

Vue.use(VueRouter)

const routes = [
    {
        path: '/rank',
        name: 'Ranking',
        component: Ranking
    },
    {
        path: '/waitforstart',
        name: 'WaitForStart',
        component: WaitForStart
    },
    {
        path: '/game',
        name: 'Game',
        component: Game
    },
    {
        path: '/:survey?',
        name: 'Home',
        component: Home
    }
]

const router = new VueRouter({
    routes
})

export default router
