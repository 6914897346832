<template>
  <div>
    <img class="logout" @click="showModal = true" alt="Wyjście" src="@/assets/logout.png">
    <div class="avground" v-if="showModal">
      <div class="modal-exit">
        <h4 class="mt-4">Czy na pewno chcesz opuścić grę?</h4>
        <table class="table mt-5" v-if="showButtons">
          <tr>
            <td @click="showModal = false">Nie</td>
            <td @click="logout">Tak</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExitComponent",
  data() {
    return {
      showModal: false,
      showButtons: true
    }
  },
  methods: {
    logout() {
      const params = new URLSearchParams();
      const config = {headers: {Authorization: `Bearer ${localStorage.getItem("token")}`},};
      this.showButtons = false;

      this.axios.post("/knt/logout", params, config)
          .then(() => {
            localStorage.clear();
            this.$router.push("/");
          })
          .catch(() => {
            this.showButtons = true;
          });
    }
  }
}
</script>

<style scoped>
.avground {
  background-color: #00000099;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  z-index: 2;
  left: 0;
  bottom: 0;
  text-align: center;
}

.modal-exit {
  border-radius: 6px;
  background-color: whitesmoke;
  width: 360px;
  max-width: 90%;
  height: 200px;
  display: inline-block;
  padding: 10px;
  z-index: 1;
  font-size: 1.2em;
}

.logout {
  position: fixed;
  left: 5px;
  width: 70px;
  bottom: 0;
  z-index: 1;
  padding: 10px;
  cursor: pointer;
}

table td {
  cursor: pointer;
}
</style>