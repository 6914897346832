<template>
  <div class="custom-container">
    <img class="bg lt" alt="Background" src="@/assets/fbg-lt.jpg">
    <img class="bg rt" alt="Background" src="@/assets/fbg-rt.png">
    <img class="bg rb" alt="Background" src="@/assets/fbg-rb.jpg">
    <img class="bg lb" alt="Background" src="@/assets/fbg-lb.jpg">
    <form class="d-flex flex-column justify-content-center align-items-center loginModal"
          :class="{loginModalShow:setLogo}">
      <img ref="logo" class="main" alt="logo" src="@/assets/logo.png" :class="{bigger : bigger && !setLogo, setLogo}">
      <div v-if="formError.name" class="error small">
        <div>!</div>
        To pole jest obowiązkowe
      </div>
      <input v-on:keyup="disableError(0)" :class="{name:formError.name}" v-model="name" required type="text"
             maxlength="200" class="form-control custom-input" placeholder="IMIĘ">
      <div v-if="formError.surname" class="error small">
        <div>!</div>
        To pole jest obowiązkowe
      </div>
      <input v-on:keyup="disableError(1)" :class="{surname: formError.surname}" v-model="surname" required type="text"
             maxlength="200" class="form-control custom-input" placeholder="NAZWISKO">

      <div v-if="loader.image" class="custom-loader loadingio-spinner-dual-ball-2pd4am92pg">
        <div class="ldio-4utaa9ogoxl">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div v-if="error.length > 0" class="error">
        <div>!</div>
        {{ error }}
      </div>
      <input v-if="setLogo" @click="submit" :class="{buttonFadeIn: button}" type="submit" value="DOŁĄCZ DO GRY" class="custom-button"/>
    </form>
    <div class="endGameModal" v-if="showEndModal">
      <div>
        <p class="close" @click="showEndModal=false">x</p>
          Test został zakończony
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Home",
  created() {
    // user leaves the tab
    document.addEventListener('blur', () => {
      alert('nara');
    });

    //setInterval(this.animation, 1500);
    if (localStorage.getItem("token") !== null) {
      const self = this;
      setTimeout(function () {
        self.getTestData()
      }, 500);
    }
    if(this.$route.query.endgame)
      this.showEndModal = true;

    this.animation();
  },
  data() {
    return {
      name: "",
      surname: "",
      bigger: false,
      showEndModal: false,
      button: false,
      setLogo: false,
      defaultError: "Wystąpił problem techniczny. Spróbuj ponownie lub zgłoś problem prowadzącemu.",
      error: "",
      formError: {name: false, surname: false},
      loader: {image: false},
      counterAnimation: 0
    }
  },
  methods: {
    disableError(which) {
      if (which === 0)
        this.formError.name = false;
      else
        this.formError.surname = false;
    },
    animation() {
      this.counterAnimation++;
      this.bigger = !this.bigger;
      const self = this;

      if (this.counterAnimation < 5) {
        setTimeout(function () {
          self.animation();
        }, 1200);
        return false
      }

      this.setLogo = true;

      setTimeout(function () {
        self.button = true;
        if((!self.$route.params.survey || self.$route.params.survey.length < 2) && !self.$route.query.endgame){
          self.error = "Nieprawidłowy link do gry";
        }
      }, 1000);

    },
    submit(e) {
      e.preventDefault();
      this.formError.surname = false;
      this.formError.name = false;
      this.error = ""
      if (this.name.trim().length < 2) {
        this.formError.name = true
        return
      }

      if (this.surname.length < 2) {
        this.formError.surname = true
        return
      }
      this.loader.image = true;
      this.loader.message = false;

      const params = new URLSearchParams();
      params.append("name", this.name.trim() + " " + this.surname.trim());
      params.append("testName", this.$route.params.survey);

      this.axios.post("/knt/create-team", params)
          .then((response) => {
            this.loader.image = false;
            let res;
            try {
              res = JSON.parse(response.request.response);
            } catch (e) {
              this.error = this.defaultError
              return false;
            }

            if (res.error) {
              this.error = res.message
            } else {
              localStorage.clear();
              localStorage.setItem("token", res.loginToken);
              const route = (res.gameData.testData.initial_test === 1) ? "Game" : "WaitForStart";
              this.$router.push({
                name: route,
                params: {testData:res.gameData.testData}
              });
            }
          })
          .catch((error) => {
            this.loader.image = false;
            this.error = this.defaultError
            console.log(error);
          });
    },
    getTestData() {
      const config = {
        headers: {Authorization: `Bearer ${localStorage.getItem("token")}`},
      };
      this.axios.get("/knt/synchronize", config)
          .then((response) => {
            let res;
            try {
              res = JSON.parse(response.request.response);
              const route = res.testData.ranking === 1 ? "Ranking" : (res.testData.initial_test === 1 || res.testData.actual_question !== null) ? "Game" : "WaitForStart";
              //push with animation loading
              if (this.counterAnimation > 3) {
                this.$router.push({
                  name: route,
                  params: {testData:res.testData}
                });
              } else {
                const self = this;
                const waitAnimation = (5-self.counterAnimation)*1000;
                setTimeout(function () {
                  res.testData.waitAnimation = waitAnimation/1000;
                  self.$router.push({
                    name: route,
                    params: {testData: res.testData}
                  });
                }, waitAnimation);
              }
            } catch (e) {
              return false;
            }

          }).catch((error) => {
        console.log(error);
      });
    }
  }
}
</script>

<style scoped>

@media (max-width: 430px) {
  .buttonFadeIn {
    font-size: 0.9em !important;
    height: 55px !important;
    width: 185px !important;
  }
  .setLogo{
    margin-top: -100% !important;
  }

  .loginModalShow{
    margin-top: 0 !important;
  }
}


* {
  font-weight: 800;
}

.endGameModal{
  position: absolute;
  left:0;
  top:0;
  right:0;
  bottom:0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000055;
}

.endGameModal .close{
  position: absolute;
  right: 5%;
  padding: 5px;
  cursor: pointer;
  top: 2%;
}

.endGameModal div{
  width: 90%;
  height: 80%;
  position: relative;
  display: flex;
  font-size: 1.4em;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  max-width: 380px;
  max-height: 180px;
  background-color: white;
}

input.name::placeholder, input.surname::placeholder {
  color: rgb(254, 71, 71);
}

.error {
  color: rgb(254, 71, 71);
  font-size: 0.7em;
  position: relative;
  top: 2em;
  line-height: 1.4em;
  padding: 0 5%;
}

.small {
  text-align: left;
  width: 95%;
}

.small div {
  top: -2px !important;
  margin-right: 6px;
  width: 18px !important;
  height: 18px !important;
  font-size: 1em !important;
  text-align: center;
}

.error div {
  line-height: 1.4em;
  border-radius: 100%;
  border: 1.9px solid rgb(254, 71, 71);
  width: 18px;
  float: left;
  margin-right: 5px;
  height: 18px;
  top: -3px;
  font-size: 1.1em;
  font-weight: 800;
  position: relative;
}


.custom-container {
  background-image: url("~@/assets/bg-w.jpg");
  background-repeat: no-repeat;
  background-color: #fff;
  display: flex;
  min-height: 560px;
  justify-content: center;
  align-items: center;
  background-size: 100% 100%;
  width: 100vw;
  position: relative;
  height: 100vh;
}

.custom-loader {
  position: absolute;
  bottom: -45px;
}

.custom-button {
  border-radius: 40px;
  position: absolute;
  left: 0;
  font-size: 0;
  right: 0;
  transition-duration: 1s;
  margin: auto;
  top: 330px;
  bottom: 0;
  height: 0;
  border: none;
  color: white;
}

.buttonFadeIn {
  color: black !important;
  font-size: 1.2em;
  text-shadow: 1px 1px 5px #777;
  background-color: #f3f3f3 !important;
  box-shadow: 2px 2px 8px #888 inset, -10px -10px 8px white inset;
  padding: 1.3em 1.6em 1.1em 1.6em;
  height: 74px;
  width: 239px;
}

.bigger {
  width: 45vw !important;
  min-width: 38vh !important;
  max-width: 900px !important;
}

.custom-input {
  border-radius: 20px;
  border: none;
  display: flex;
  align-items: center;
  height: 40px;
  padding-top: 0.75em;
  margin-top: 2em;
  width: 88%;
  font-size: 0.85em;
}

input:focus{
  box-shadow: none !important;
  outline: 0 !important;
  border: none !important;
}

.loginModalShow {
  margin-top: 100px;
  width: 90% !important;
  height: 300px !important;
}

.loginModal {
  text-align: center;
  background-color: #f3f3f3 !important;
  border-radius: 10%;
  position: relative;
  transition-duration: 2s;
  width: 0;
  padding-top: 100px;
  max-width: 450px;
  height: 0;
  box-shadow: 10px 10px 15px #bbb, -2px -2px 10px white;
}

.setLogo {
  width: 90% !important;
  min-width: auto !important;
  max-height: 300px;
  margin-top: -80%;
  transition-duration: 2s !important;
}

.main {
  display: inherit;
  transition-duration: 1.8s;
  width: 35vw;
  min-width: 30vh;
  max-width: 750px;
}

.bg {
  position: absolute;
}

.lt {
  left: 0;
  top: 0;
  object-fit: cover;
  max-height: 62vh;
  max-width: 44vw;
  min-width: 250px;
}

.rt {
  right: 0;
  top: 0;
  max-width: 28vw;
  max-height: 20vh;
  min-width: 100px;
}

.rb {
  bottom: 0;
  right: 0;
  max-width: 40vw;
  max-height: 40vh;
  min-width: 240px;
}

.lb {
  bottom: 0;
  left: 0;
  max-width: 17vw;
  min-width: 110px;
  max-height: 20vh;
}
</style>