<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'app',
  created() {
    document.title = "Know Your Team";
  }
}
</script>

<style>

@import '~bootstrap/dist/css/bootstrap.css';

#app {
  font-family: Spartan, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

@keyframes ldio-4utaa9ogoxl-o {
  0% {
    opacity: 1;
    transform: translate(0 0)
  }
  49.99% {
    opacity: 1;
    transform: translate(25px, 0)
  }
  50% {
    opacity: 0;
    transform: translate(25px, 0)
  }
  100% {
    opacity: 0;
    transform: translate(0, 0)
  }
}

@keyframes ldio-4utaa9ogoxl {
  0% {
    transform: translate(0, 0)
  }
  50% {
    transform: translate(25px, 0)
  }
  100% {
    transform: translate(0, 0)
  }
}

.ldio-4utaa9ogoxl div {
  position: absolute;
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.ldio-4utaa9ogoxl div:nth-child(1) {
  background: #FFDE59;
  animation: ldio-4utaa9ogoxl 1.3333333333333333s linear infinite;
  animation-delay: -0.6666666666666666s;
}

.ldio-4utaa9ogoxl div:nth-child(2) {
  background: #003e72;
  animation: ldio-4utaa9ogoxl 1.3333333333333333s linear infinite;
  animation-delay: 0s;
}

.ldio-4utaa9ogoxl div:nth-child(3) {
  background: #FFDE59;
  animation: ldio-4utaa9ogoxl-o 1.3333333333333333s linear infinite;
  animation-delay: -0.6666666666666666s;
}

.loadingio-spinner-dual-ball-2pd4am92pg {
  width: 100px;
  height: 100px;
  margin: 5px 0 5px 50px;
  display: inline-block;
  overflow: hidden;
}

.ldio-4utaa9ogoxl {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}

.ldio-4utaa9ogoxl div {
  box-sizing: content-box;
}

/* generated by https://loading.io/ */
</style>
